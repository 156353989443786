<template>
  <div>
    <header-slot />
    <b-nav card-header pills class="m-0">
      <b-nav-item
        :to="`/${route}/schedules/schedules/`"
        v-if="isModulRrhh"
        exact
        exact-active-class="active "
        :link-classes="['px-3', bgTabsNavs]"
        >Schedules</b-nav-item
      >
      <b-nav-item
        :to="`/${route}/schedules/report/`"
        exact
        exact-active-class="active "
        :link-classes="['px-3', bgTabsNavs]"
        >Report by Module</b-nav-item
      >
      <b-nav-item
        :to="`/${route}/schedules/report-by-user/`"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
        v-if="isModulRrhh || isModulManagment"
        >Report by User</b-nav-item
      >
      <b-nav-item
        :to="`/${route}/schedules/overtime/`"
        exact
        exact-active-class="active border-primary-i"
        :link-classes="['px-3', bgTabsNavs]"
      >
        Overtime
        <span v-if="S_COUNTER_OVERTIME > 0" class="ml-1">
          <feather-icon
            icon
            :badge="S_COUNTER_OVERTIME > 99 ? '99+' : S_COUNTER_OVERTIME"
            badge-classes="badge-danger"
          />
        </span>
      </b-nav-item>
      <b-nav-item
        :to="`/${route}/schedules/justifications`"
        exact
        exact-active-class="active border-primary-i"
        :link-classes="['px-3', bgTabsNavs]"
      >
        Justifications

        <span v-if="S_COUNTER_JUSTIFICATIONS > 0" class="ml-2">
          <feather-icon
            icon
            :badge="
              S_COUNTER_JUSTIFICATIONS > 99 ? '99+' : S_COUNTER_JUSTIFICATIONS
            "
            badge-classes="badge-danger"
          />
        </span>
      </b-nav-item>
    </b-nav>

    <b-card class="border-top-primary border-3 border-table-radius">
      <router-view :key="this.$route.name" />
    </b-card>
  </div>
</template>

<script>
/* import ModalRequestLoan from "./modals/ModalRequestLoan.vue"; */
import { mapGetters, mapState, mapActions } from "vuex";
import SchedulesService from "@/views/commons/components/schedules/services/schedules.service.js";

export default {
  name: "SchedulesComponent",
  components: {
    /* ModalRequestLoan */
  },

  data() {
    return {};
  },
  created() {},
  computed: {
    ...mapGetters({
      bigWindow: "app/bigWindow",
      currentUser: "auth/currentUser",
      researchLoans: "loans-store/researchLoans",
      counterTab: "loans-store/counterTab",
      modalRequest: "loans-store/modalRequest",
      isSupervisor: "auth/isSupervisor",
    }),
    ...mapState("SchedulesStore", {
      S_COUNTER_OVERTIME: (state) => state.S_COUNTER_OVERTIME,
      S_COUNTER_JUSTIFICATIONS: (state) => state.S_COUNTER_JUSTIFICATIONS,
    }),
    route() {
      return this.$route.meta.route;
    },
    isModulRrhh() {
      return this.moduleId == 17;
    },
    isModulManagment() {
      return this.moduleId == 16;
    },
    isModulBoostCredit() {
      return this.moduleId == 7;
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  mounted() {
    if (this.moduleId == 4) {
      this.setCounters();
    }
  },
  methods: {
    ...mapActions({
      loadCounterTab: "SchedulesStore/A_SET_COUNTERS_TABS_SCHEDULES",
    }),

    async setCounters() {
      const { totalJus, totalOver } =
        await SchedulesService.getSchedulesCounter({ moduleid: this.moduleId });
      this.loadCounterTab({
        totalJus,
        totalOver,
      });
    },
  },
};
</script>

<style></style>
